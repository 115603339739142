import { css } from 'styled-components'

export const fallbackGrayColor = '#dddfe2'

export const FallbackBoxStyle = css`
  border-radius: 4px;
  background-color: ${fallbackGrayColor};
  pointer-events: none;

  animation: skeleton-fade-in 0.3s linear forwards, skeleton-glow 1s linear infinite alternate;
  animation-delay: 0s, 0.3s;
  opacity: 0;

  @keyframes skeleton-fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes skeleton-glow {
    0% {
      background: rgba(206, 217, 224, 0.2);
      border-color: rgba(206, 217, 224, 0.2);
    }
    to {
      background: rgba(92, 112, 128, 0.2);
      border-color: rgba(92, 112, 128, 0.2);
    }
  }
`
